// asumisen tietopankki pois käytöstä 25.3.2025 - pistin vaa valikosta piiloo.
import React, { Component } from 'react';
import { connect } from 'react-redux'
import Iframe from '../UI/Iframe';
import {asumisentietopankkiWidgetURI} from "../../shared/util";
import CurrentUser from '../UI/CurrentUser';

import Preloader from '../UI/Preloader/Preloader';

class Page_Asumisentietopankki extends Component {

  loader() {
    if (this.props.isLoading) {
      // rendataan syrjään, jos on jo jotain artikkeleita mitä näyttää
      return <Preloader absolute={ this.props.articles }/>
    }
  }

  // Backendissä web view, missä koodattuna istonews widgetti asumisentietopankkihässäköillä.
  // Backendissä autentikointi, joten tarvitaan toimiva token messiin.
  // Teemu 10.10.2019
  pwa_backend_asumisentietopankki_url () {
    return asumisentietopankkiWidgetURI + '?token=' + localStorage.getItem("jwtToken");
  }
  


  render() {

    if (this.props.appOffline)
      return <div>Ei verkkoyhteyttä</div>
    else
      return (
        <div>
          <CurrentUser subtitle="Asumisen tietopankki"/>
          <Iframe src={this.pwa_backend_asumisentietopankki_url()}></Iframe>
        </div>
      )
  }
/*
  componentDidMount() {
    this.props.dispatch(loadBulletin(this.props.userID));
  }
*/
}

const mapStateToProps = (state) => {
  return {
    appOffline : state.app.online ? false : true,
  }
};

export default connect(mapStateToProps)(Page_Asumisentietopankki);
