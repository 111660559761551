import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import {connect} from 'react-redux';
import {CLOSE_MENU} from '../actions/actionTypes'
import NewItemsBadge from './UI/NewItemsBadge'
import {getUnseenCount} from '../shared/util'
// import { stat } from 'fs';


class Menuitems extends Component {

  constructor(props) {
    super(props);

    // This binding is necessary to make `this` work in the callback
    this.closeMenu = this.closeMenu.bind(this);
  }

  closeMenu() {
    this.props.dispatch( { type: CLOSE_MENU } );
  }

  menuLinks() {

    // Jos valikon luokka navbar-nav, tulostetaas bootstrap 4 valikkoluokat nappuloihin.
    let linkClass = this.props.className === 'navbar-nav' ? 'nav-item nav-link' : 'menu-button';
    
    // Nappulat
    let boardButton, maestrong_button = '';
    if (this.props.currentUser.board_member) {
      boardButton = 
        <NavLink key="button-board-archive" to="/board-archive" id="button-board-arhive" className={linkClass} activeClassName="active" aria-label="Hallituksen tietopankki" onClick={this.closeMenu}>
          <span className="menuicon icon-folder"></span>
          <span className="menu-label">Hallituksen tietopankki</span>
          <NewItemsBadge count={this.props.unseen_board_archive} />
        </NavLink>
      ;

      maestrong_button = 
        <NavLink key="button-maestong" to="/maestrong" id="button-maestrong" className={linkClass} activeClassName="active" aria-label="MaestroNG ostolaskut" onClick={this.closeMenu}>
          <span className="menuicon icon-folder"></span>
          <span className="menu-label">Ostolaskut</span>
        </NavLink>
      ;
    }


      let shareholderButton = '';
      if (this.props.currentUser.shareholder)
      shareholderButton = 
          <NavLink key="button-shareholder-archive" to="/shareholder-archive" id="button-shareholder-arhive" className={linkClass} activeClassName="active" aria-label="Osakkaiden tietopankki" onClick={this.closeMenu}>
            <span className="menuicon icon-folder"></span>
            <span className="menu-label">Osakkaiden tietopankki</span>
            <NewItemsBadge count={this.props.unseen_shareholder_archive} />
          </NavLink>
        ;

    let pelsu = '';
    if (this.props.currentUser && this.props.currentUser.pelsu_url) {
      pelsu = 
        <a key="button-pelsu" href={this.props.currentUser.pelsu_url} id="button-pelsu" className={linkClass} aria-label="Pelastussuunnitelma" onClick={this.closeMenu}>
          <span className="menuicon icon-pelsu"></span>
          <span className="menu-label">Pelastus-<br/>suunnitelma</span>
        </a>
        ;
    }

    let bulletins = <NavLink exact key="button-bulletin" to="/bulletin" id="button-bulletin" className={linkClass} activeClassName="active" aria-label="Tiedotteet" onClick={this.closeMenu}>
                      <span className="menuicon icon-bulletin"></span>
                      <span className="menu-label">Tiedotteet</span>
                      <NewItemsBadge count={this.props.unseen_bulletins} />
                    </NavLink>
    ; 
    let documents =  <NavLink key="button-documents" to="/documents" id="button-documents" className={linkClass} activeClassName="active" aria-label="Dokumentit" onClick={this.closeMenu}>
                      <span className="menuicon icon-documents"></span>
                      <span className="menu-label">Dokumentit</span>
                      <NewItemsBadge count={this.props.unseen_documents} />
                    </NavLink>
    ;
    let error_report = 
                      <NavLink key="button-error-report" to="/maintenance_request" id="button-error-report" className={linkClass} activeClassName="active" aria-label="Huoltopyyntö" onClick={this.closeMenu}>
                        <span className="menuicon icon-maintenance"></span>
                        <span className="menu-label">Huoltopyyntö</span>
                      </NavLink>
    ;
    let contact = 
                  <NavLink key="button-contact-form" to="/contact" id="button-contact-form" className={linkClass} activeClassName="active" aria-label="Yhteydenotto" onClick={this.closeMenu}>
                      <span className="menuicon icon-contact"></span>
                      <span className="menu-label">Yhteydenotto</span>
                    </NavLink>
    ;
    let asumisentietopankki = 
                  <NavLink key="button-asumisentietopankki" to="/asumisentietopankki" id="button-asumisentietopankki" className={linkClass} activeClassName="active" aria-label="Asumisen tietopankki" onClick={this.closeMenu}>
                      <span className="menuicon icon-folder"></span>
                      <span className="menu-label">Asumisen tietopankki</span>
                    </NavLink>
    ;   
    let forms =                     
            <a key="button-forms" href="https://kontuoy.fi/asiointi/" id="button-forms" className={linkClass} aria-label="Sähköinen asiointi" onClick={this.closeMenu}>
              <span className="menuicon icon-contact"></span>
              <span className="menu-label">Sähköinen asiointi</span>
            </a>
    let news = 
              <NavLink exact key="button-news" to="/news" id="button-news" className={linkClass} activeClassName="active" aria-label="Uutiset" onClick={this.closeMenu}>
                  <span className="menuicon icon-documents"></span>
                  <span className="menu-label">Uutiset</span>
                </NavLink>
    ;
    let settings = 
                  <NavLink  key="button-settings" to="/settings" id="button-settings" className={linkClass} activeClassName="active" aria-label="Kirjaudu ulos" onClick={this.closeMenu}>
                  <span className="menuicon icon-settings"></span>
                  <span className="menu-label">Asetukset</span>
                </NavLink>
    ;

    let info = 
            <NavLink key="button-info" to="/info/" id="button-info" className={linkClass} activeClassName="active" aria-label="Info" onClick={this.closeMenu}>
                <span className="menuicon icon-info"></span>
                <span className="menu-label">Info</span>
              </NavLink>
    ;
    let privacy_policy = 
                    <a key="button-privacypolicy" href="https://kontuoy.fi/tietosuojaseloste-2/" id="button-privacypolicy" className={linkClass} aria-label="Tietosuojaseloste" onClick={this.closeMenu}>
                      <span className="menuicon icon-info"></span>
                      <span className="menu-label">Tietosuoja- seloste</span>
                    </a>
    ;
    let login = 
                <NavLink key="button-login" to="/auth/login" id="button-login" className={linkClass} activeClassName="active" aria-label="Kirjaudu sisään" onClick={this.closeMenu}>
                <span className="menuicon icon-login"></span>
              <span className="menu-label">Kirjaudu sisään</span>
            </NavLink>
    ;
    let register = 
                <NavLink key="button-register" to="/auth/register" id="button-register" className={linkClass} activeClassName="active" aria-label="Rekisteröidy" onClick={this.closeMenu}>
                <span className="menuicon icon-register"></span>
              <span className="menu-label">Rekisteröidy</span>
            </NavLink>
    ;    

    // Kirjautuneena - ja löytyy taloyhtiö
    if (this.props.isAuthenticated && this.props.hasCurrentUser) {

        return [
          bulletins,
          documents,
          boardButton,
          maestrong_button,
          shareholderButton,
          error_report,
          forms,
          pelsu,
          // contact,
          news,        
          settings,
          info,
          // asumisentietopankki - pois käytöstä 25.3.2025
          ];

    // Kirjautuneena, mutta ei taloyhtiötä valittuna
    } else if (this.props.isAuthenticated){

      return [
        info,
        privacy_policy,
        settings
      ];

    } else {
      return [
        login,
        register,
        info,
        privacy_policy
      ];
    }
  }


  render() {

    let classes = this.props.className + " menu";

    return (
      <div id={this.props.htmlId} className={classes}>
          { this.menuLinks() }
      </div>
    );
  }
}




const MapStateToProps = (state) => {

  const { myinfo, auth, bulletin, board_archive, shareholder_archive, documents} = state;
  return {
    currentUser : myinfo.currentUser,
    hasCurrentUser : myinfo.currentUser.id ? true : false,
    isAuthenticated : auth.isAuthenticated,
    unseen_shareholder_archive : shareholder_archive.error || getUnseenCount('shareholder_document', shareholder_archive.articles),
    unseen_board_archive : board_archive.error || getUnseenCount('board_document', board_archive.articles),
    unseen_bulletins : bulletin.error || getUnseenCount('bulletin', bulletin.articles), 
    unseen_documents : documents.error ||  getUnseenCount('document', documents.articles),
  }
}

export default connect (MapStateToProps, null, null, {
  pure: false
})(Menuitems);

// export default withRouter(connect(MapStateToProps)(Header));
// export default Header;
